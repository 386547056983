// General
import React from 'react';
import { NavLink } from 'react-router-dom';
// Context
import { useTheme } from '../../../Contexts/ThemeContext';
import { useLanguage } from '../../../Contexts/LanguageContext';

function Card({prop}) {
  const { theme } = useTheme();
  const { translations } = useLanguage();
  const lang = translations.currentLanguage;
  return (
    <div className={`Desktop__Home__Card__Container ${theme}`}>
      <div className='Desktop__Home__Card__Context'>
        <div className='Desktop__Home__Card__Image'><NavLink to={`/products/${prop.id}`}><img src={`${process.env.PUBLIC_URL}/Images/Props/${prop.image}.jpg`} alt={`${prop.image}`} /></NavLink></div>
        <div className='Desktop__Home__Card__Info'>
          <h4>{lang === "TUR" ? prop.name_tur : lang === "ENG" && prop.name_eng}</h4>
          <p>{lang === "TUR" ? prop.short_desc_tur : lang === "ENG" && prop.short_desc_eng}</p>
        </div>
      </div>
    </div>
  )
}

export default Card;