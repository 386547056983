// General
import React from "react";
import { NavLink } from "react-router-dom";
// Contexts
import { useTheme } from "../../../Contexts/ThemeContext";
import { useLanguage } from "../../../Contexts/LanguageContext";

function Contact() {
  const { theme } = useTheme();
  const { translations } = useLanguage();
  return (
    <div className={`Mobile__Home__Contact__Container ${theme}`}>
      <div>
        <h5>{translations.ContactText1}</h5>
        <h2>{translations.ContactTitle}</h2>
        <p>{translations.ContactText2}</p>
        <NavLink to='/contact'>{translations.ContactButton}</NavLink>
      </div>
    </div>
  )
}

export default Contact