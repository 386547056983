// General
import React from 'react';
import { NavLink } from 'react-router-dom';
// Style
import '../../Assets/CSS/Desktop/footer.css'
// Context
import { useLanguage } from '../../Contexts/LanguageContext';
import { useTheme } from '../../Contexts/ThemeContext';
// Icons
import { BiLogoFacebookCircle } from "react-icons/bi";
import { FaMapLocationDot } from "react-icons/fa6";

function Footer() {
  const { theme } = useTheme();
  const { translations } = useLanguage();
  return (
    <div className={`Desktop__Footer__Container ${theme}`}>
      <div>
        <div className='Desktop__Footer__Content'>
          <p>&copy; {new Date().getFullYear()} Sırtsan - {translations.FooterText}</p>
        </div>
        <div className='Desktop__Footer__Icons'>
          <NavLink to='https://www.facebook.com/people/Sirtsan-%C5%9Eenol-Erhan/pfbid0JMBwu6h8D2dNGLPrdsZFhthPuevwhQVtTxCiPheSXKK3GBVmbrHwrJ8cNuSoTdJcl/'><BiLogoFacebookCircle /></NavLink>
          <NavLink to='https://www.google.com/maps/place/S%C4%B1rtsan+Fabrika/@41.0144981,28.8862891,14z/data=!4m6!3m5!1s0x14caaf65d1420207:0xe86b07f32fe5e1eb!8m2!3d41.026039!4d28.9170513!16s%2Fg%2F11fmh3_v4h?entry=tts'><FaMapLocationDot /></NavLink>
        </div>
      </div>
    </div>
  )
}

export default Footer;