import React from 'react';
// Styles
import '../../../Assets/CSS/Mobile/home.css';
// Components
import Slider from './Slider';
import About from './About';
import Contact from './Contact';
import Products from './Products';
// Contexts
import { useTheme } from '../../../Contexts/ThemeContext';
import { useLanguage } from '../../../Contexts/LanguageContext';
// Images
import img_Slider01 from '../../../Assets/Images/Home/Mobile/Slider01.jpg';
import img_Slider02 from '../../../Assets/Images/Home/Mobile/Slider02.jpg';
import img_Slider03 from '../../../Assets/Images/Home/Mobile/Slider03.jpg';

function Home() {
  const { theme } = useTheme();
  const { translations } = useLanguage();
  const slides = [
    {
      image: img_Slider01, title: translations.Slider1, desc: translations.Slider1Desc, button: translations.Slider1Button,
    },
    {
      image: img_Slider02, title: translations.Slider2, desc: translations.Slider2Desc, button: translations.Slider1Button,
    },
    {
      image: img_Slider03, title: translations.Slider3, desc: translations.Slider3Desc, button: translations.Slider1Button,
    }
  ];
  return (
    <div className={`Mobile__Home__Container ${theme}`}>
      <Slider slides={slides} />
      <About />
      <Products />
      <Contact />
    </div>
  )
}

export default Home;