// General
import React, { useState } from 'react';
// Style
import '../../../Assets/CSS/Desktop/products.css';
// Contexts
import { useTheme } from '../../../Contexts/ThemeContext';
// Components
import Card from './Card';
// Icons
import { BiChevronLeft, BiChevronRight } from 'react-icons/bi';
// Props
import data from '../../../Assets/DefaultProps/props.json';

function Products() {
  const { theme } = useTheme();
  const [page, setPage] = useState(1);
  const totalItems = data.length;
  const itemsPerPage = 4;
  const totalPages = Math.ceil(totalItems / itemsPerPage);
  const startIndex = (page - 1) * itemsPerPage;
  const visibleData = data.slice(startIndex, startIndex + itemsPerPage);
  const handlePrevPage = () => {
    page === 1 ? setPage(totalPages) : setPage(page - 1);
  }
  const handleNextPage = () => {
    page === totalPages ? setPage(1) : setPage(page + 1);
  }
  return (
    <div className={`Desktop__Home__Products__Container ${theme}`}>
      <button onClick={handlePrevPage} disabled={page === 1}><BiChevronLeft /></button>
      <div>
        {
          visibleData.map((data, index) => (
            <Card key={index} prop={data} />
          ))
        }
      </div>
      <button onClick={handleNextPage} disabled={page === totalPages}><BiChevronRight /></button>
    </div>
  )
}

export default Products;