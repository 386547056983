// General
import React from 'react';
import { NavLink } from 'react-router-dom';
// Context
import { useTheme } from '../../../Contexts/ThemeContext';
import { useLanguage } from '../../../Contexts/LanguageContext';
// Icons
import { BiRightArrowAlt } from 'react-icons/bi';
import { PiFactoryDuotone } from "react-icons/pi";
import { AiFillProduct } from "react-icons/ai";
import { GiZipper } from "react-icons/gi";
import { GiConverseShoe } from "react-icons/gi";
// Images
import img_service01 from '../../../Assets/Images/Services/Services01.jpg';
import img_service02 from '../../../Assets/Images/Services/Services02.jpg';
import img_service03 from '../../../Assets/Images/Services/Services03.jpg';
import img_service04 from '../../../Assets/Images/Services/Services04.jpg';

function Services() {
  const { theme } = useTheme();
  const { translations } = useLanguage();
  const services = [
    {title: translations.Service1, icon: <PiFactoryDuotone />, desc: translations.Service1ShortDesc, image: img_service01},
    {title: translations.Service2, icon: <AiFillProduct />, desc: translations.Service2ShortDesc, image: img_service02},
    {title: translations.Service3, icon: <GiZipper />, desc: translations.Service3ShortDesc, image: img_service03},
    {title: translations.Service4, icon: <GiConverseShoe />, desc: translations.Service4ShortDesc, image: img_service04}];
  return (
    <div className={`Desktop__Home__Services__Container ${theme}`}>
      <div>
        {services.map((service, index) => (
          <div key={index}>
            <div className='Desktop__Home__Services__Icon'><p>{service.icon}</p></div>
            <div className='Desktop__Home__Services__Texts'>
              <h4>{service.title}</h4>
              <p>{service.desc}</p>
              <NavLink to='/services'>{translations.ServicesExploreButton}<BiRightArrowAlt /></NavLink>
            </div>
            <div className='Desktop__Home__Services__Image'>
              <img src={service.image} alt={`homeservice_image_${index}`} />
              <NavLink to='/services'>+</NavLink>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default Services