// General
import React, { useEffect, useState, useRef } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
// Styles
import '../../Assets/CSS/Mobile/header.css';
// Contexts
import { useTheme } from '../../Contexts/ThemeContext';
import { useLanguage } from '../../Contexts/LanguageContext';
// Icons
import { FaCaretDown, FaCaretLeft } from "react-icons/fa";
import { GrRadial, GrRadialSelected } from "react-icons/gr";
// Images
import LogoBlack from '../../Assets/Images/Header/LogoBlack.png';
import LogoWhite from '../../Assets/Images/Header/LogoWhite.png';

function Header() {
  const { theme, setTheme } = useTheme();
  const { translations, setLanguage } = useLanguage();
  const [isTop, setIsTop] = useState(true);
  const location = useLocation();
  const currentPath = location.pathname;
  const logo = isTop || theme === 'Dark' ? LogoWhite : LogoBlack;
  const [ isMenuOpen, setIsMenuOpen ] = useState(false);
  const [ isSettingsOpen, setIsSettingsOpen ] = useState(false);
  const [ isThemeOpen, setIsThemeOpen ] = useState(false);
  const [ isLangOpen, setIsLangOpen ] = useState(false);
  const menuRef = useRef();
  useEffect(() => {
    setTimeout(() => { 
      window.scrollTo({top: 0});
      setIsMenuOpen(false);
      if(currentPath === "/") {setIsTop(true);}
      else {setIsTop(false);}
    }, 50);
  }, [currentPath]);
  useEffect(() => {
    const handleScroll = () => {
      const scrollTop = window.pageYOffset;
      if (scrollTop === 0) {
        setIsTop(true);
      } else {
        setIsTop(false);
      }
    };
    if (currentPath !== "/" || isMenuOpen) {
      setIsTop(false);
    } else {
      handleScroll();
      window.addEventListener('scroll', handleScroll);
      return () => {
        window.removeEventListener('scroll', handleScroll);
      };
    }
  }, [currentPath, isMenuOpen])
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setIsMenuOpen(false);
        setIsSettingsOpen(false);
        setIsThemeOpen(false);
        setIsLangOpen(false);
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);
  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
    if(!isMenuOpen){
      setIsSettingsOpen(false);
      setIsThemeOpen(false);
      setIsLangOpen(false);
    }
  }
  const toggleSettings = () => {
    setIsSettingsOpen(!isSettingsOpen);
    if(!isSettingsOpen){
      setIsThemeOpen(false);
      setIsLangOpen(false);
    }
  }
  const toggleTheme = () => {
    setIsThemeOpen(!isThemeOpen);
  }
  const toggleLang = () => {
    setIsLangOpen(!isLangOpen);
  }
  return (
    <div className={`Mobile__Header__Container ${isTop ? 'Top' : ''} ${theme}`}>
      <div>
        <div ref={menuRef}>
          <button onClick={toggleMenu}><img src={logo} alt='logo'/></button>
          {isMenuOpen && (
            <div>
              <NavLink to='/'>{translations.HomePage}</NavLink>
              <NavLink to='/products'>{translations.ProductsPage}</NavLink>
              <NavLink to='/services'>{translations.ServicesPage}</NavLink>
              <NavLink to='/contact'>{translations.ContactPage}</NavLink>
              <div>
                <button onClick={toggleSettings}>{translations.Settings}{isSettingsOpen ? <FaCaretDown /> : <FaCaretLeft />}</button>
                {isSettingsOpen && (
                  <div>
                    <div>
                      <button onClick={toggleTheme}>{translations.Theme}{isThemeOpen ? <FaCaretDown /> : <FaCaretLeft />}</button>
                      {isThemeOpen && (
                        <div>
                          <button onClick={() => setTheme('Light')}>{theme === 'Light' ? <GrRadialSelected /> : <GrRadial />}{translations.Light}</button>
                          <button onClick={() => setTheme('Dark')}>{theme === 'Dark' ? <GrRadialSelected /> : <GrRadial />}{translations.Dark}</button>
                        </div>
                      )}
                    </div>
                    <div>
                    <button onClick={toggleLang}>{translations.Language}{isLangOpen ? <FaCaretDown /> : <FaCaretLeft />}</button>
                      {isLangOpen && (
                        <div>
                          <button onClick={() => setLanguage('Turkish')}>{translations.currentLanguage === 'TUR' ? <GrRadialSelected /> : <GrRadial />}{translations.Turkish}</button>
                          <button onClick={() => setLanguage('English')}>{translations.currentLanguage === 'ENG' ? <GrRadialSelected /> : <GrRadial />}{translations.English}</button>
                        </div>
                      )}
                    </div>
                  </div>
                )}
              </div>
            </div>
          )}
        </div>
      </div>
      <hr />
    </div>
  );
}
export default Header;