// General
import React from 'react';
// Context
import { useTheme } from '../../../Contexts/ThemeContext';
import { useLanguage } from '../../../Contexts/LanguageContext';
// Icons
import { BiErrorAlt } from 'react-icons/bi';

function Error404() {
  const { theme } = useTheme();
  const { translations } = useLanguage();
  return (
    <div className={`ErrorPage ${theme}`}>
      <BiErrorAlt /><p>{translations.Error404}</p>
    </div>
  )
}

export default Error404;