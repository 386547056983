// General
import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
// Contexts
import { useTheme } from '../../../Contexts/ThemeContext';
import { useLanguage } from '../../../Contexts/LanguageContext';
// Props
import data from '../../../Assets/DefaultProps/props.json';
// Icons
import { BiErrorAlt, BiLoaderAlt } from 'react-icons/bi';

function Details() {
  const { id } = useParams();
  const productId = parseInt(id);
  const { theme } = useTheme();
  const { translations } = useLanguage();
  const lang = translations.currentLanguage;
  const navigate = useNavigate();
  const [product, setProduct] = useState(null);
  const [loading, setLoading] = useState(true);
  const [loadingText, setLoadingText] = useState('.');
  useEffect(() => {
    setLoading(true);
    const fetchProduct = async () => {
      const product = await data.find(p => p.id === productId);
      setProduct(product);
      setLoading(false);
    }
    fetchProduct();
  }, [productId]);
  useEffect(() => {
    if(loading) {
      const loadingTextArray = [`.`, `..`, `...`];
      let index = 0;
      const intervalId = setInterval(() => {
        index = (index + 1) % loadingTextArray.length;
        setLoadingText(loadingTextArray[index]);
      }, 500);
      return () => clearInterval(intervalId);
    }
  }, [translations.Loading, loading]);
  if(loading) {
    return (
      <div className={`ErrorPage ${theme}`}>
        <BiLoaderAlt className='Spinner' /><p>{translations.Loading}{loadingText}</p>
      </div>
    )
  }
  if(!product) {
    return (
      <div className={`ErrorPage ${theme}`}>
        <BiErrorAlt /><p>{translations.PropNotFound}</p>
      </div>
    )
  }
  return (
    <div className={`Mobile__Details__Container ${theme}`}>
      <button onClick={() => navigate(-1)}>{translations.BackButton}</button>
      <div className='Mobile__Details__Content'>
        <div className='Mobile__Details__Image'>
          <img src={`${process.env.PUBLIC_URL}/Images/Props/${product.image}.jpg`} alt={`product${product.id}`} />
        </div>
        <div className='Mobile__Details__Info'>
          <div>
            <h2>{lang === 'TUR' ? product.name_tur : lang === 'ENG' && product.name_eng}</h2>
            <p>{lang === 'TUR' ? product.desc_tur : lang === 'ENG' && product.desc_eng}</p>
          </div>
        </div>
      </div>
    </div>
  )
}
export default Details;