// General
import React, { useEffect, useState, useRef } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
// Styles
import '../../Assets/CSS/Desktop/header.css';
// Contexts
import { useTheme } from '../../Contexts/ThemeContext';
import { useLanguage } from '../../Contexts/LanguageContext';
// Icons
import { DiAptana } from 'react-icons/di';
import { FaCaretDown, FaCaretLeft } from "react-icons/fa";
import { GrRadial, GrRadialSelected } from "react-icons/gr";
// Images
import LogoBlack from '../../Assets/Images/Header/LogoBlack.png';
import LogoWhite from '../../Assets/Images/Header/LogoWhite.png';

function Header() {
  const { theme, setTheme } = useTheme();
  const { translations, setLanguage } = useLanguage();
  const [isTop, setIsTop] = useState(true);
  const location = useLocation();
  const currentPath = location.pathname;
  const logo = isTop || theme === 'Dark' ? LogoWhite : LogoBlack;
  const [ isSettingsOpen, setIsSettingsOpen ] = useState(false);
  const [ isThemeOpen, setIsThemeOpen ] = useState(false);
  const [ isLangOpen, setIsLangOpen ] = useState(false);
  const settingsRef = useRef();
  useEffect(() => {
    setTimeout(() => { 
      window.scrollTo({top: 0});
      if(currentPath === "/") {setIsTop(true);}
    }, 50);
    if(currentPath !== "/") {setIsTop(false);}
    else {
      const handleScroll = () => {
        const scrollTop = window.pageYOffset;
        if (scrollTop === 0) {setIsTop(true);} 
        else {setIsTop(false);}
      };
      window.addEventListener('scroll', handleScroll);
      return () => {window.removeEventListener('scroll', handleScroll);};
    }
  }, [currentPath]);
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (settingsRef.current && !settingsRef.current.contains(event.target)) {
        setIsSettingsOpen(false);
        setIsThemeOpen(false);
        setIsLangOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);
  const toggleSettings = () => {
    setIsSettingsOpen(!isSettingsOpen);
    if(!isSettingsOpen){
      setIsThemeOpen(false);
      setIsLangOpen(false);
    }
  }
  const toggleTheme = () => {
    setIsThemeOpen(!isThemeOpen);
  }
  const toggleLang = () => {
    setIsLangOpen(!isLangOpen);
  }
  return (
    <div className={`Desktop__Header__Container ${isTop ? 'Top' : ''} ${theme}`}>
      <div>
        <div className='Desktop__Header__Logo'><NavLink to='/'><img src={logo} alt='logo' /></NavLink></div>
        <div className='Desktop__Header__Navbar'>
          <ul>
            <li className={`${currentPath === "/" && "Active"}`}><NavLink to='/'>{translations.HomePage}</NavLink></li>
            <li className={`${currentPath.includes('/products') && "Active"}`}><NavLink to='/products'>{translations.ProductsPage}</NavLink></li>
            <li className={`${currentPath === "/services" && "Active"}`}><NavLink to='/services'>{translations.ServicesPage}</NavLink></li>
            <li className={`${currentPath === "/contact" && "Active"}`}><NavLink to='/contact'>{translations.ContactPage}</NavLink></li>
            <li>
              <div className='Desktop__Header__Settings' ref={settingsRef}>
                <button onClick={toggleSettings}><DiAptana /></button>
                {isSettingsOpen && (
                  <div>
                    <div>
                      <button onClick={toggleTheme}>{translations.Theme}{isThemeOpen ? <FaCaretDown /> : <FaCaretLeft />}</button>
                      {isThemeOpen && (
                      <div>
                        <button onClick={() => setTheme('Light')}>{theme === 'Light' ? <GrRadialSelected /> : <GrRadial />}{translations.Light}</button>
                        <button onClick={() => setTheme('Dark')}>{theme === 'Dark' ? <GrRadialSelected /> : <GrRadial />}{translations.Dark}</button>
                      </div>
                      )}
                    </div>
                    <div>
                      <button onClick={toggleLang}>{translations.Language}{isLangOpen ? <FaCaretDown /> : <FaCaretLeft />}</button>
                      {isLangOpen && (
                      <div>
                        <button onClick={() => setLanguage('Turkish')}>{translations.currentLanguage === 'TUR' ? <GrRadialSelected /> : <GrRadial />}{translations.Turkish}</button>
                        <button onClick={() => setLanguage('English')}>{translations.currentLanguage === 'ENG' ? <GrRadialSelected /> : <GrRadial />}{translations.English}</button>
                      </div>
                      )}
                    </div>
                  </div>
                )}
              </div>
            </li>
          </ul>
        </div>
      </div>
      <hr />
    </div>
  );
}
export default Header;