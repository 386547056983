const today = new Date().getFullYear();
const serviceyear = today - 1995;

const translations = {
    currentLanguage: "TUR",
    HomePage: "ANA SAYFA",
    ProductsPage: "ÜRÜNLERİMİZ",
    ServicesPage: "HİZMETLERİMİZ",
    ContactPage: "İLETİŞİM",
    Settings: "AYARLAR",
    Theme: "Tema",
    Light: "Aydınlık",
    Dark: "Karanlık",
    Language: "Dil",
    Turkish: "Türkçe",
    English: "English",
    Admin: "Yönetici Paneli",
    FooterText: "Tüm Haklar Saklıdır.",
    Slider1: "Baskı",
    Slider1Desc: "Kişi ve isteğe göre hazırlanan eva, poli ve poliüretan xp köpük baskılı ürünlerimiz, isteğe ve talebe göre kalıplar ve bıçaklar ile hazırlanmaktadır.",
    Slider1Button: "Hizmetlerimiz",
    Slider2: "Laminasyon",
    Slider2Desc: "Eva kaplama ve laminasyon hizmetimizle, siz değerli müşterilerimizi en iyi kalitedeki ürünlerle buluşturuyoruz.",
    Slider2Button: "Hizmetlerimiz",
    Slider3: "Çanta Malzemeleri",
    Slider3Desc: "En iyi kalitede file, köpük ve fermuar gibi çanta ile ilgili tüm malzeme tedariğini sizlere sunuyoruz.",
    Slider3Button: "Hizmetlerimiz",
    About: "Sırtsan",
    AboutShortDesc: `1995 yılında Şenol Erhan tarafından İstanbul Başakşehir'de kurulan Sırtsan Ortapedi Dünyası ${serviceyear} yıllık tecrübesi ile sizlere üretim ve hizmet vermeye devam ediyor.`,
    AboutButton: "Daha Fazla",
    AboutTitle: "Sırtsan Ortopedi Dünyası",
    AboutSubtext: "Yıllardır biriktirdiğimiz deneyimlerimizi sizlere aktarıyoruz.",
    AboutText1: "Türkiye'nin dört bir yanına ortopedik sırtlık, özel eva baskılı modeller, laminasyon ve çanta malzemeleri ile hizmet sunan Sırtsan Ortapedi Dünyası ürünlerini sipariş etmek için +905335143186 numaralı telefonu arayarayak bizimle iletişime geçebilirsiniz.",
    AboutText2: "Ayrıca sirtsan.com web sitesi üzerinden ürünlerimizi görebilir, kendi işinize en uygun modeli tasarlamak ve uygun kalıpları görmek için Maltepe mahallesi, Litros Yolu sokak, Topkapı Ticaret Merkezi, No: 8-10/2, Zeytinburnu / İstanbul da bulunan ofisimizi ziyaret edebilirsiniz.",
    WatchVideo: "BİZE ULAŞIN",
    AboutFeature1: "Kaliteli Çanta Baskısı",
    AboutFeature1Desc: "Kişi ve isteğe göre hazırlanan eva, poli ve poliüretan xp köpük baskılı ürünlerimiz, isteğe ve talebe göre kalıplar ve bıçaklar ile hazırlanmaktadır.",
    AboutFeature2: "Laminasyon",
    AboutFeature2Desc: "Eva kaplama ve laminasyon hizmetimizle, siz değerli müşterilerimizi en iyi kalitedeki ürünlerle buluşturuyoruz.",
    AboutFeature3: "Çanta Malzemeleri",
    AboutFeature3Desc: "En iyi kalitede file, köpük ve fermuar gibi çanta ile ilgili tüm malzeme tedariğini sizlere sunuyoruz.",
    ContactTitle: "SIRTSAN ORTOPEDİ DÜNYASI",
    ContactText1: `1995 yılında Şenol Erhan tarafından İstanbul Başakşehir'de kurulan Sırtsan Ortapedi Dünyası ${serviceyear} yıllık tecrübesi ile sizlere üretim ve hizmet vermeye devam ediyor.`,
    ContactText2: `${serviceyear} yıldır müşteri odaklı çalışarak, müşterilerimize en hızlı ve en iyi kalitedeki hizmeti sunmaya devam ediyoruz.`,
    ContactButton: "Bizimle İletişime Geçin",
    AllProducts: "Tümü",
    BackButton: "GERİ DÖN",
    Price: "Fiyat",
    Service1: "Baskı",
    Service1Desc: "Sırtsan kalitesi ile isteğinize göre baskı ürünleri hazırlanır. Kişi ve isteğe göre hazırlanan eva, poli ve poliüretan xp köpük baskılı ürünlerimiz, isteğe ve talebe göre kalıplar ve bıçaklar ile hazırlanmaktadır.",
    Service1ShortDesc: "Sırtsan kalitesi ile isteğinize göre baskı ürünleri hazırlanır.",
    Service2: "Laminasyon",
    Service2Desc: "Sırtsan kalitesi ile film laminasyon yapıştırma hizmetinden faydalanın. Eva kaplama ve laminasyon hizmetimizle, siz değerli müşterilerimizi en iyi kalitedeki ürünlerle buluşturuyoruz.",
    Service2ShortDesc: "Sırtsan kalitesi ile film laminasyon yapıştırma hizmetinden faydalanın.",
    Service3: "Çanta Malzemeleri",
    Service3Desc: "Sırtsan kalitesi ile çanta imalatınızda aradığınız ürünü burada bulabilirsiniz. En iyi kalitede file, köpük ve fermuar gibi çanta ile ilgili tüm malzeme tedariğini sizlere sunuyoruz.",
    Service3ShortDesc: "Sırtsan kalitesi ile çanta imalatınızda aradığınız ürünü burada bulabilirsiniz.",
    Service4: "Ayakkabı Malzemeleri",
    Service4Desc: "Sırtsan kalitesi ile çanta imalatınızda aradığınız ürünü burada bulabilirsiniz. En iyi kalitede jurdan ve ayakkabı tabanı gibi ayakkabı ile ilgili malzemelerin tedariğini sizlere sunuyoruz.",
    Service4ShortDesc: "Sırtsan kalitesi ile ayakkabı & terlik imalatınızda aradığınız ürünü burada bulabilirsiniz.",
    ServiceProductsExplore: "Ürünleri Keşfet",
    ServicesExploreButton: "Hizmetlerimiz",
    Benefit1: "Fayda 1",
    Benefit2: "Fayda 2",
    Benefit3: "Fayda 3",
    ContactInfos: "İletişim Bilgileri",
    ContactAddress: "Maltepe mahallesi, Litros Yolu sokak, Topkapı Ticaret Merkezi, No: 8-10/2, Zeytinburnu / İstanbul",
    OpeningHours: "Açılış Saatleri",
    Weekdays: "Hafta İçi",
    Saturday: "Cumartesi",
    Sunday: "Pazar",
    Closed: "Kapalı",
    Subject: "Konu",
    Name: "İsim",
    Mail: "Mail",
    Message: "Mesajınız",
    Send: "GÖNDER",
    Error404: "Üzgünüz! Bu sayfayı bulamadık.",
}

export default translations;