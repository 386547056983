// General
import React, { useState } from 'react';
import GoogleMapReact from 'google-map-react';
// Style
import '../../../Assets/CSS/Desktop/contact.css';
// Context
import { useTheme } from '../../../Contexts/ThemeContext';
import { useLanguage } from '../../../Contexts/LanguageContext';
// Icons
import { FaLocationDot } from "react-icons/fa6";
import { IoMdMail } from "react-icons/io";
import { FaPhone } from "react-icons/fa";

function Contact() {
  const { theme } = useTheme();
  const { translations } = useLanguage();
  const startLocation = {center: {lat: 41.026192756327305, lng: 28.91709421229018}, zoom: 16};
  const [subject, setSubject] = useState('');
  const [name, setName] = useState('');
  const [mail, setMail] = useState('');
  const [message, setMessage] = useState('');
  const handleSubmit = (e) => {
    e.preventDefault();
    console.log({ name, mail, subject, message });
    setSubject("");
    setName("");
    setMail("");
    setMessage("");
  };
  return (
    <div className={`Desktop__Contact__Container ${theme}`}>
      <div className='Desktop__Contact__Map'>
      <GoogleMapReact bootstrapURLKeys={{ key: "AIzaSyBgHFuJ9oUy23VUYQTX1y36TJWM2fBPeHE" }} defaultCenter={startLocation.center} defaultZoom={startLocation.zoom}>
      </GoogleMapReact>
      </div>
      <div className='Desktop__Contact__Content' onSubmit={handleSubmit}>
        <div className='Desktop__Contact__Info'>
          <div className='Desktop__Contact__Info__Top'>
            <h4>{translations.ContactInfos}</h4>
            <ul>
              <li><FaLocationDot /><p>{translations.ContactAddress}</p></li>
              <li><IoMdMail /><p>semiherhann@gmail.com</p></li>
              <li><FaPhone /><p>+90 533 514 3186</p></li>
            </ul>
          </div>
          <div className='Desktop__Contact__Info__Bottom'>
            <h4>{translations.OpeningHours}</h4>
            <div>
              <div>
                <ul>
                  <li><p>{translations.Weekdays}</p></li>
                  <li><p>{translations.Saturday}</p></li>
                  <li><p>{translations.Sunday}</p></li>
                </ul>
              </div>
              <div className='Desktop__Contact__Info__Bottom__Hours'>
                <ul>
                  <li><p>09:00 - 18:00</p></li>
                  <li><p>09:00 - 13:00</p></li>
                  <li><p>{translations.Closed}</p></li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <form className='Desktop__Contact__Form' onSubmit={handleSubmit}>
          <div>
            <input type="text" id="subject" value={subject} onChange={(e) => setSubject(e.target.value)} placeholder={translations.Subject} required />
            <input type="text" id="name" value={name} onChange={(e) => setName(e.target.value)} placeholder={translations.Name} required />
            <input type="email" id="mail" value={mail} onChange={(e) => setMail(e.target.value)} placeholder={translations.Mail} required />
          </div>
          <div>
            <textarea id="message" value={message} onChange={(e) => setMessage(e.target.value)} placeholder={translations.Message} required />
          </div>
          <div>
            <button type='submit'>{translations.Send}</button>
          </div>
        </form>
      </div>
    </div>
  )
}

export default Contact;