// General
import React from 'react';
import { NavLink } from 'react-router-dom';
// Styles
import '../../../Assets/CSS/Desktop/services.css';
// Contexts
import { useTheme } from '../../../Contexts/ThemeContext';
import { useLanguage } from '../../../Contexts/LanguageContext';
// Icons
import { PiFactoryDuotone } from "react-icons/pi";
import { AiFillProduct } from "react-icons/ai";
import { GiZipper } from "react-icons/gi";
import { GiConverseShoe } from "react-icons/gi";
// Images
import img_service01 from '../../../Assets/Images/Services/Services01.jpg';
import img_service02 from '../../../Assets/Images/Services/Services02.jpg';
import img_service03 from '../../../Assets/Images/Services/Services03.jpg';
import img_service04 from '../../../Assets/Images/Services/Services04.jpg';

function Services() {
  const { theme } = useTheme();
  const { translations } = useLanguage();
  const services = [
    {
      id: 1,
      title: translations.Service1,
      desc: translations.Service1Desc,
      image: img_service01,
      icon: <PiFactoryDuotone />,
      productType: 'baski'
    },
    {
      id: 2,
      title: translations.Service2,
      desc: translations.Service2Desc,
      image: img_service02,
      icon: <AiFillProduct />,
      productType: 'canta'
    },
    {
      id: 3,
      title: translations.Service3,
      desc: translations.Service3Desc,
      image: img_service03,
      icon: <GiZipper />,
      productType: 'cantamal'
    },
    {
      id: 4,
      title: translations.Service4,
      desc: translations.Service4Desc,
      image: img_service04,
      icon: <GiConverseShoe />,
      productType: 'ayakmal'
    }
  ];
  const totalLines = services.length / 2;
  return (
    <div className={`Desktop__Services__Container ${theme}`}>
      {Array.from({length: totalLines}, (_, i) => (
        <div>
          <div className='Desktop__Services__Content__Left'>
            <div>
              <div className='Desktop__Services__Title'>
                {services[(i*2)].icon}<h4>{services[(i*2)].title}</h4>
              </div>
              <div className='Desktop__Services__Desc'>
                <p>{services[(i*2)].desc}</p>
                <NavLink to={`/products?type=${services[(i*2)].productType}`}>{translations.ServiceProductsExplore}</NavLink>
              </div>
              <div className='Desktop__Services__Image'>
                <img src={services[(i*2)].image} alt={`image${services[(i*2)].id}`} />
              </div>
            </div>
          </div>
          <div className='Desktop__Services__Content__Right'>
            <div>
              <div className='Desktop__Services__Title'>
                {services[((i*2) + 1)].icon}<h4>{services[((i*2) + 1)].title}</h4>
              </div>
              <div className='Desktop__Services__Desc'>
                <p>{services[((i*2) + 1)].desc}</p>
                <NavLink to={`/products?type=${services[((i*2) + 1)].productType}`}>{translations.ServiceProductsExplore}</NavLink>
              </div>
              <div className='Desktop__Services__Image'>
                <img src={services[((i*2) + 1)].image} alt={`image${services[((i*2) + 1)].id}`} />
              </div>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
}

export default Services;