// General
import React, { useEffect, useState } from "react";
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';
// Styles
import './Assets/CSS/module.css';
// Context
import { ThemeProvider, useTheme } from './Contexts/ThemeContext';
import { LanguageProvider } from './Contexts/LanguageContext';
// Desktop
// Components
import DHeader from './DesktopItems/Components/Header';
import DFooter from './DesktopItems/Components/Footer';
// Pages
import DHome from './DesktopItems/Pages/Home/index';
import DProducts from './DesktopItems/Pages/Products/index';
import DProductDetails from './DesktopItems/Pages/Products/Details';
import DServices from './DesktopItems/Pages/Services/index';
import DContact from './DesktopItems/Pages/Contact/index';
import DError404 from './DesktopItems/Pages/Error/Error404';
// Mobile
// Components
import MHeader from './MobileItems/Components/Header';
import MFooter from './MobileItems/Components/Footer';
// Pages
import MHome from './MobileItems/Pages/Home/index';
import MProducts from './MobileItems/Pages/Products/index';
import MProductDetails from './MobileItems/Pages/Products/Details';
import MServices from './MobileItems/Pages/Services/index';
import MContact from './MobileItems/Pages/Contact/index';
import MError404 from './MobileItems/Pages/Error/Error404';
// Icons
import { BiSolidUpArrowAlt } from 'react-icons/bi';

function App() {
  const isMobile = useMediaQuery({ query: '(max-width: 767px)' });
  return (
    <Router>
      <ThemeProvider>
        <LanguageProvider>
          {isMobile ? <MobileContent /> : <DesktopContent />}
        </LanguageProvider>
      </ThemeProvider>
    </Router>
  );
}

function DesktopContent() {
  const { theme } = useTheme();
  const [showGoToBack, setShowGoToBack] = useState(false);
  useEffect(() => {
    const handleScroll = () => {
      const scrollTop = window.pageYOffset;
      if (scrollTop === 0 || scrollTop < window.innerHeight * 0.4) {setShowGoToBack(false);} 
      else {setShowGoToBack(true);}
    };
    window.addEventListener('scroll', handleScroll);
    return () => {window.removeEventListener('scroll', handleScroll);};
  }, []);
  const goToTop = () => {
    window.scrollTo({top: 0, behavior: 'smooth'});
  };
  return(
    <>
    <div>
      <DHeader />
      <div>
        <Routes>
          <Route path="/" Component={DHome} />
          <Route path="/products" Component={DProducts} />
          <Route path="/products/:id" Component={DProductDetails} />
          <Route path="/services" Component={DServices} />
          <Route path="/contact" Component={DContact} />
          <Route path="/*" Component={DError404} />
        </Routes>
      </div>
      <DFooter />
    </div>
    {showGoToBack && <button onClick={() => goToTop()} className={`GoToTop ${theme}`}><BiSolidUpArrowAlt /></button>}
    </>
  );
}

function MobileContent() {
  return(
    <div>
      <MHeader />
      <div>
        <Routes>
          <Route path="/" Component={MHome} />
          <Route path="/products" Component={MProducts} />
          <Route path="/products/:id" Component={MProductDetails} />
          <Route path="/services" Component={MServices} />
          <Route path="/contact" Component={MContact} />
          <Route path="/*" Component={MError404} />
        </Routes>
      </div>
      <MFooter />
    </div>
  );
}

export default App;