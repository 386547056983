const today = new Date().getFullYear();
const serviceyear = today - 1995;

const translations = {
    currentLanguage: "ENG",
    HomePage: "HOME",
    ProductsPage: "PRODUCTS",
    ServicesPage: "SERVICES",
    ContactPage: "CONTACT",
    Settings: "SETTINGS",
    Theme: "Theme",
    Light: "Light",
    Dark: "Dark",
    Language: "Language",
    Turkish: "Türkçe",
    English: "English",
    Admin: "Admin Panel",
    FooterText: "All Rights Reserved.",
    Slider1: "Printings",
    Slider1Desc: "Our EVA, poly and polyurethane xp foam printed products, are prepared with molds and knives according to request and demand.",
    Slider1Button: "Our Services",
    Slider2: "Lamination",
    Slider2Desc: "With our Eva coating and lamination service, we bring together our valued customers with the best quality products.",
    Slider2Button: "Our Services",
    Slider3: "Bag Materials",
    Slider3Desc: "We offer you the supply of all bag-related materials such as mesh, foam and zippers of the best quality.",
    Slider3Button: "Our Services",
    About: "Sırtsan",
    AboutShortDesc: `Founded by Şenol Erhan in Istanbul Başakşehir in 1995, Sırtsan continues to provide production and services with ${serviceyear} years of experience.`,
    AboutButton: "Learn More",
    AboutTitle: "Sırtsan",
    AboutSubtext: "We share our accumulated experiences with you for years.",
    AboutText1: "Sırtsan, offering orthopedic back supports, special Eva printed models, lamination and bag materials to all parts of Turkey, you can contact us by calling +905335143186 to order our products.",
    AboutText2: "You can also visit our office at Maltepe district, Litros Yolu street, Topkapı Trade Center, No: 8-10/2, Zeytinburnu / Istanbul to see our products on sirtsan.com website and to design the most suitable model for your business and see the appropriate molds.",
    WatchVideo: "CONTACT US",
    AboutFeature1: "Quality Bag Printings",
    AboutFeature1Desc: "Our EVA, poly and polyurethane xp foam printed products, are prepared with molds and knives according to request and demand.",
    AboutFeature2: "Lamination",
    AboutFeature2Desc: "With our Eva coating and lamination service, we bring together our valued customers with the best quality products.",
    AboutFeature3: "Bag Materials",
    AboutFeature3Desc: "We offer all materials related to bags such as best quality net, foam, and zipper.",
    ContactTitle: "SIRTSAN",
    ContactText1: `Founded by Şenol Erhan in Istanbul Başakşehir in 1995, Sırtsan continues to provide production and services with ${serviceyear} years of experience.`,
    ContactText2: `For ${serviceyear} years, by working customer-oriented, we continue to offer the fastest and highest quality service to our customers.`,
    ContactButton: "Contact With Us",
    AllProducts: "All",
    BackButton: "Back",
    Price: "Price",
    Service1: "Printings",
    Service1Desc: "With Sırtsan quality, printing products are prepared according to your wishes. Our EVA, poly and polyurethane xp foam printed products, which are prepared according to the person and demand, are prepared with molds and knives according to request and demand.",
    Service1ShortDesc: "With Sırtsan quality, printing products are prepared according to your wishes.",
    Service2: "Lamination",
    Service2Desc: "Benefit from film lamination and gluing service with Sirtsan quality. With our Eva coating and lamination service, we bring together our valued customers with the best quality products.",
    Service2ShortDesc: "Benefit from film lamination and gluing service with Sirtsan quality.",
    Service3: "Bag Materials",
    Service3Desc: "You can find the product you are looking for in your bag manufacturing with Sirtsan quality here. We offer you the supply of all bag-related materials such as mesh, foam and zippers of the best quality.",
    Service3ShortDesc: "You can find the product you are looking for in your bag manufacturing with Sirtsan quality here.",
    Service4: "Shoe Materials",
    Service4Desc: "You can find the product you are looking for in your bag manufacturing with Sirtsan quality here. We offer you the supply of shoe-related materials such as curtains and shoe soles of the best quality.",
    Service4ShortDesc: "You can find the product you are looking for in your bag manufacturing with Sirtsan quality here.",
    ServiceProductsExplore: "Explore Products",
    ServicesExploreButton: "Our Services",
    ContactInfos: "Contact Details",
    ContactAddress: "Maltepe district, Litros Yolu street, Topkapi trade center, No: 8-10/2, Zeytinburnu / Istanbul",
    OpeningHours: "Opening Hours",
    Weekdays: "Monday - Friday",
    Saturday: "Saturday",
    Sunday: "Sunday",
    Closed: "Closed",
    Subject: "Subject",
    Name: "Name",
    Mail: "Mail",
    Message: "Your Message Here!",
    Send: "Send",
    Error404: "Sorry! We couldn't find this page.",
}

export default translations;