// General
import React, { useState , forwardRef } from 'react';
import { NavLink } from 'react-router-dom';
// Contexts
import { useTheme } from '../../../Contexts/ThemeContext';
import { useLanguage } from '../../../Contexts/LanguageContext';
// Images
import img_About from '../../../Assets/Images/Home/About.jpg';
import SignClassic from '../../../Assets/Images/Home/SignClassic.png';
import SignWhite from '../../../Assets/Images/Home/SignWhite.png';
// Videos
import intro from '../../../Assets/Videos/introdesktop.mp4';

const About = forwardRef((props, ref) => {
  const { theme } = useTheme();
  const { translations } = useLanguage();
  const img_Sign = theme === 'Light' ? SignClassic : SignWhite;
  const [isVideoOpen, setVideoOpen] = useState(false);
  const handleOpenVideo = () => {
    setVideoOpen(true);
  };
  const handleCloseVideo = () => {
    setVideoOpen(false);
  };
  return (
      <div ref={ref} className={`Desktop__Home__About__Container ${theme}`}>
        <div>
          <div className='Desktop__Home__About__Header'>
            <p>{translations.AboutTitle}</p>
            <h2>{translations.AboutSubtext}</h2>
          </div>
          <div className='Desktop__Home__About__Content'>
            <div className='Desktop__Home__About__Content__Left'>
              <h5>{translations.AboutText1}</h5>
              <p>{translations.AboutText2}</p>
              <div><img src={img_Sign} alt='sign' /></div>
            </div>
            <div className='Desktop__Home__About__Content__Mid'>
              <div className='Desktop__Home__About__Content__Mid__Left'>
                <div />
              </div>
              <div className='Desktop__Home__About__Content__Mid__Right'>
                <div className='Desktop__Home__About__Content__Mid__Right__Image'>
                  <img src={img_About} alt='about' />
                </div>
                <div className='Desktop__Home__About__Content__Mid__Right__Button'>
                  <NavLink to="/contact" >{translations.WatchVideo}</NavLink>
                </div>
              </div>
            </div>
            <div className='Desktop__Home__About__Content__Right'>
              <ul>
                <li>
                  <h5>{translations.AboutFeature1}</h5>
                  <p>{translations.AboutFeature1Desc}</p>
                </li>
                <li>
                  <h5>{translations.AboutFeature2}</h5>
                  <p>{translations.AboutFeature2Desc}</p>
                </li>
                <li>
                  <h5>{translations.AboutFeature3}</h5>
                  <p>{translations.AboutFeature3Desc}</p>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
  );
});

const VideoModal = ({ handleClose }) => {
  const { theme } = useTheme();
  return (
    <div className={`VideoPlayer__Container ${theme}`}>
      <div className="VideoPlayer__Content">
        <span className="VideoPlayer__Close" onClick={handleClose}>&times;</span>
        <video width="100%" controls>
          <source src={intro} type="video/mp4" />
          Tarayıcınız video etiketini desteklemiyor.
        </video>
      </div>
    </div>
  );
};

export default About;