import {createContext, useContext, useEffect, useState} from 'react';
import Turkish from '..//Assets/Languages/Turkish';
import English from '../Assets/Languages/English';

const LanguageContext = createContext();

const LanguageProvider = ({children}) => {
    const [language, setLanguage] = useState(localStorage.getItem('Language') || 'Turkish');
    const [translations, setTranslations] = useState(Turkish);
    useEffect(() => {
        localStorage.setItem("Language", language);
        if(language === "Turkish") {
            setTranslations(Turkish);
        }
        else if(language === "English") {
            setTranslations(English);
        }
    }, [language]);
    const values = {translations, setLanguage};
    return <LanguageContext.Provider value={values}>{children}</LanguageContext.Provider>
}

const useLanguage = () => useContext(LanguageContext);

export {LanguageProvider, useLanguage};