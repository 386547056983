import React, { useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';
// Icons
import { BiChevronLeft, BiChevronRight } from 'react-icons/bi';

function Slider({ slides }) {
  const [currentSlide, setCurrentSlide] = useState(0);
  useEffect(() => {
    const interval = setInterval(() => {
      currentSlide === slides.length - 1 ? setCurrentSlide(0) : setCurrentSlide(currentSlide + 1);
    }, 5000);
    return () => clearInterval(interval);
  }, [currentSlide, slides.length]);
  const PrevSlide = () => {
    currentSlide === 0 ? setCurrentSlide(slides.length - 1) : setCurrentSlide(currentSlide - 1);
  };
  const NextSlide = () => {
    currentSlide === slides.length - 1 ? setCurrentSlide(0) : setCurrentSlide(currentSlide + 1);
  };
  return (
    <div className='Mobile__Home__Slider__Container' style={{ backgroundImage: `url(${slides[currentSlide].image})` }}>
      <button onClick={PrevSlide}><BiChevronLeft /></button>
      <div className='Mobile__Home__Slider__Content'>
        <h1>{slides[currentSlide].title}</h1>
        <p>{slides[currentSlide].desc}</p>
        <NavLink to='/services'>{slides[currentSlide].button}</NavLink>
      </div>
      <button onClick={NextSlide}><BiChevronRight /></button>
    </div>
  );
}

export default Slider;