import {createContext, useContext, useEffect, useState} from 'react';

const ThemeContext = createContext();

const ThemeProvider = ({children}) => {
    const [theme, setTheme] = useState(localStorage.getItem('Theme') || 'Light');
    useEffect(() => {
        localStorage.setItem("Theme", theme);
    }, [theme])
    const values = {theme, setTheme};
    return <ThemeContext.Provider value={values}>{children}</ThemeContext.Provider>
}

const useTheme = () => useContext(ThemeContext);

export {useTheme, ThemeProvider};