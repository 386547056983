// General
import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
// Style
import '../../../Assets/CSS/Mobile/products.css';
// Contexts
import { useTheme } from '../../../Contexts/ThemeContext';
import { useLanguage } from '../../../Contexts/LanguageContext';
// Components
import Card from './Card';
// Icons
import { BiChevronLeft, BiChevronRight } from 'react-icons/bi';
// Props
import data from '../../../Assets/DefaultProps/props.json';

function Products() {
  const { theme } = useTheme();
  const { translations } = useLanguage();
  const currentLanguage = translations.currentLanguage;
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const startType = searchParams.get('type');
  const [selectedType, setSelectedType] = useState(startType || 'all');
  const [page, setPage] = useState(1);
  useEffect(() => {
    setPage(1);
  }, [selectedType]);
  const types = ['all', 'baski', 'canta', 'cantamal', 'ayakmal'];
  const typesTUR = [translations.AllProducts, 'BASKI', 'ÇANTA', 'ÇANTA MAL.', 'AYAKKABI MAL.'];
  const typesENG = [translations.AllProducts, 'Printings', 'Bags', 'Bag Mat.', 'Shoe Mat.'];
  const displayedTypes = currentLanguage === 'TUR' ? typesTUR : currentLanguage === 'ENG' && typesENG;
  const filteredData = selectedType === 'all' ? data : data.filter(p => p.type === selectedType);
  const totalItems = filteredData.length;
  const itemsPerPage = 6;
  const totalPages = Math.ceil(totalItems / itemsPerPage);
  const startIndex = (page - 1) * itemsPerPage;
  const visibleData = filteredData.slice(startIndex, startIndex + itemsPerPage);
  const handlePrevPage = () => {
    page === 1 ? setPage(totalPages) : setPage(page - 1);
  }
  const handleNextPage = () => {
    page === totalPages ? setPage(1) : setPage(page + 1);
  }
  return (
    <div className={`Mobile__Products__Container ${theme}`}>
      <div>
        <nav className='Mobile__Products__Navbar'>
          <ul>
            {displayedTypes.map((type, index) => (
              <li key={index}>
                <button onClick={() => setSelectedType(types[index])}>{type}</button>
              </li>
            ))}
          </ul>
        </nav>
        <div className='Mobile__Products__Content'>
          {
            visibleData.map((data, index) => (
              <Card key={index} prop={data} />
            ))
          }
        </div>
        <div className='Mobile__Products__PageSwitcher'>
            {
              totalPages > 1 && (
                <>
                <button onClick={handlePrevPage} disabled={page === 1}><BiChevronLeft /></button>
                {totalItems < 3 ? (
                  Array.from({ length: totalPages }, (_, i) => (
                    <button onClick={() => setPage(i+1)} disabled={i+1 === page}>{i+1}</button>
                  ))
                ) : (
                  <>
                  {(page - 1) >= 1 && <button onClick={() => setPage(page - 1)}>{page - 1}</button>}
                  <button disabled={true}>{page}</button>
                  {(page + 1) <= totalPages && <button onClick={() => setPage(page + 1)}>{page + 1}</button>}
                  </>
                )}
                <button onClick={handleNextPage} disabled={page === totalPages}><BiChevronRight /></button>
                </>
              )
            }
        </div>
      </div>
    </div>
  )
}

export default Products;