// General
import React from "react";
import { NavLink } from "react-router-dom";
// Contexts
import { useTheme } from "../../../Contexts/ThemeContext";
import { useLanguage } from "../../../Contexts/LanguageContext";

function Contact() {
  const { theme } = useTheme();
  const { translations } = useLanguage();
  return (
    <div className={`Desktop__Home__Contact__Container ${theme}`}>
      <div>
        <div className='Desktop__Home__Contact__Left'>
          <p>{translations.ContactText1}</p>
          <h2>{translations.ContactTitle}</h2>
        </div>
        <div className='Desktop__Home__Contact__Right'>
          <p>{translations.ContactText2}</p>
          <NavLink to='/contact'>{translations.ContactButton}</NavLink>
        </div>
      </div>
    </div>
  )
}

export default Contact